import React from 'react'
import Tourimg from '../components/images/tourimg.jpg'
import './rightimage.css'
const RightImage = () => {
  return (
    <div className='main-img'>
      <img src={Tourimg} alt="Tour"/>
    </div>
  )
}

export default RightImage

