import React from 'react'

const Footer = () => {
  return (
    <div className='footer'>
        <p>
            All Copyrights Reserved 2023 © Dwivedi Tour&Tarvels Pvt. Ltd.
        </p>
    </div>
  )
}

export default Footer